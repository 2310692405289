<template>
  <!--Logo start-->
  <div class="logo-main">
    <img :src="logoSrc" alt="Logo" width="30" height="30" />
  </div>
  <!--Logo End-->
</template>

<script>
export default {
  props: {
    color: { type: Boolean, default: false },
    companyNamespace: { type: String, default: 'ahmadcompany' }
  },
  computed: {
    logoSrc() {
      const timestamp = new Date().getTime();
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
      //return `${apiBaseUrl}/csms/assets/${this.companyNamespace}/logo?_=${timestamp}`;
      return require('@/assets/images/logo/logo-d.png');
    }
  }
}
</script>